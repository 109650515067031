import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { Link } from "gatsby";

import { isLoggedIn } from "services/auth";

// hooks
import { cartContext } from "providers/CartProvider";
// import useLocalStorage from "hooks/useLocalStorage";

import Layout from "components/Layout";
import SEO from "components/Seo";
import CartItem from "components/cart/CartItem";

import { StyledCartHolder } from "components/cart/cartStyles";

const CartPage = () => {
  const siteContext = useContext(cartContext);
  const [cartDetails, setCartDetails] = useState([]);
  const [cartSubTotal, setCartSubTotal] = useState(0);
  const [allClasses, setAllClasses] = useState([]);
  const [attendeesReady, setAttendeesReady] = useState(false);

  useEffect(() => {
    async function fetchDetails() {
      // fetch entire list of classes, if not set yet in 'allClasses'
      if (allClasses.length < 1) {
        try {
          const idsArray = siteContext.cart.map(x => x.id);
          const postURL = `${process.env.GATSBY_API_URL}/courses/schedules-by-id`;

          const response = await axios.post(postURL, {
            ids: JSON.stringify(idsArray),
          });
          // console.log("Main -> response.data", response.data);
          if (response.status === 200) {
            setAllClasses(response.data);
          }
        } catch (e) {
          // error
        }
      }

      const dataWithQtys = allClasses
        .map((item, i) => {
          const match = siteContext.cart.find(x => x.id === item.id);
          if (match) {
            return {
              qty: match.qty,
              attendees: match.attendees,
              ...item,
            };
          }

          return null;
        })
        .filter(x => x);
      // console.log("fetchDetails -> dataWithQtys", dataWithQtys);
      setCartDetails(dataWithQtys);
    }

    fetchDetails();
  }, [siteContext, allClasses]);

  useEffect(() => {
    function getTotal() {
      if (cartDetails.length > 0) {
        const total = cartDetails.reduce(
          (acc, curr) => acc + Number(curr.price) * curr.qty,
          0
        );
        setCartSubTotal(total);
      } else {
        setCartSubTotal(0);
      }
    }

    function checkNamesEmails() {
      const allSeats = cartDetails.map(x => x.attendees);

      const tempArr = [];
      allSeats.forEach((item, i) => {
        tempArr.push(...item);
      });

      const testAll = tempArr.reduce((tot, cur) => {
        if (cur.name === "" || cur.email === "") {
          return [...tot, "empty found"];
        }

        return tot;
      }, []);

      if (testAll.length === 0) {
        setAttendeesReady(true);
      } else {
        setAttendeesReady(false);
      }
    }

    getTotal();
    checkNamesEmails();
  }, [cartDetails]);

  const updateQty = (id, direction) => {
    const newCart = siteContext.cart.map(item => {
      if (item.id === id) {
        if (direction === "+") {
          item.qty = Number(item.qty) + 1;
        } else {
          item.qty = Number(item.qty) - 1 >= 0 ? Number(item.qty) - 1 : 0;
        }
      }

      return item;
    });

    siteContext.updateQty(newCart);
  };

  const updateQtyInput = (val, id) => {
    const newCart = siteContext.cart.map(item => {
      if (item.id === id) {
        item.qty = val;
      }

      return item;
    });

    siteContext.updateQty(newCart);
  };

  const updateNamesEmails = (namesEmails, id) => {
    const newCart = siteContext.cart.map((item, i) => {
      if (item.id === id) {
        item.attendees = namesEmails;
      }

      return item;
    });

    siteContext.updateQty(newCart);
  };

  const handleItemDelete = id => {
    siteContext.removeProduct(id);

    // remove item from cart details
    const newCartDetails = cartDetails.filter(x => x.id !== id);
    setCartDetails(newCartDetails);

    siteContext.makeToast("Item removed.");
  };

  const emptyCart = () => {
    siteContext.emptyCart();
    siteContext.makeToast("Cart has been emptied.");
    setCartSubTotal(0);
    setCartDetails([]);
  };

  return (
    <Layout>
      <SEO title="Cart" />
      <section>
        <Container>
          <Row>
            <Col xs="12">
              <h2>Your Cart:</h2>

              <button
                type="button"
                onClick={emptyCart}
                className="btn btn-info btn-sm"
              >
                Empty Cart
              </button>
              <StyledCartHolder key={`key_${cartDetails.length}`}>
                <ul>
                  {cartDetails.map((item, i) => (
                    <li key={i}>
                      <CartItem
                        siteContext={siteContext}
                        item={item}
                        updateQty={updateQty}
                        updateQtyInput={updateQtyInput}
                        updateNamesEmails={updateNamesEmails}
                        itemIndex={i}
                        handleDelete={handleItemDelete}
                      />
                    </li>
                  ))}
                </ul>

                {cartSubTotal && cartSubTotal !== 0 ? (
                  <>
                    <div className="cart-total">
                      <h6>Subtotal:</h6>
                      <p>${Number(cartSubTotal).toFixed(2)}</p>
                    </div>

                    <div>
                      {attendeesReady ? (
                        <Link
                          to="/checkout"
                          className="btn btn-lg btn-success"
                          state={{ cartDetails }}
                        >
                          Checkout
                        </Link>
                      ) : (
                        <p className="font-weight-bold text-danger">
                          To proceed with checkout, all seats must have a name
                          and email set above.
                        </p>
                      )}
                    </div>
                  </>
                ) : (
                  <p>Your cart is currently empty.</p>
                )}
              </StyledCartHolder>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default CartPage;
