import React, { useEffect, useState } from "react";
import { formatToMilitaryDate } from "helpers/index";

import { StyledCartItem, StyledQty, StyledNameEmailRow } from "./cartStyles";

const CartItem = props => {
  const {
    item,
    itemIndex,
    siteContext,
    updateQty,
    updateQtyInput,
    updateNamesEmails,
    handleDelete,
  } = props;

  // console.log("item", item);
  const [namesEmails, setNamesEmails] = useState([]);

  useEffect(() => {
    if (!item.attendees) {
      const tempNamesEmails = Array.from(new Array(item.qty), (x, i) => ({
        name: "",
        email: "",
      }));
      setNamesEmails(tempNamesEmails);
    } else {
      const tempNamesEmails = item.attendees;
      setNamesEmails(tempNamesEmails);
    }
  }, []);

  const handleQtyChange = (e, id) => {
    const val = parseInt(e.target.value);
    const curQty = item.qty;

    if (curQty < val) {
      const tempArr = [];
      const diff = val - curQty;
      const tempObj = { name: "", email: "" };
      for (let p = 0; p < diff; p++) {
        tempArr.push(tempObj);
      }
      setNamesEmails([...namesEmails, ...tempArr]);
      updateQtyInput(val, id);
      updateNamesEmails([...namesEmails, ...tempArr], id);
      return true;
    }

    if (curQty > val) {
      if (val > 0) {
        const newNamesEmails = namesEmails.slice(0, val);
        console.log("handleQtyChange -> newNamesEmails", newNamesEmails);
        setNamesEmails(newNamesEmails);
        updateQtyInput(val, id);
        updateNamesEmails(newNamesEmails, id);
      }
      return true;
    }

    return true;
  };

  const handleNameUpdate = (name, index) => {
    const tempNamesEmails = namesEmails.slice();
    tempNamesEmails[index].name = name;

    setNamesEmails(tempNamesEmails);
    updateNamesEmails(tempNamesEmails, itemIndex);
  };

  const handleEmailUpdate = (email, index) => {
    const tempNamesEmails = namesEmails.slice();
    tempNamesEmails[index].email = email;

    setNamesEmails(tempNamesEmails);
    updateNamesEmails(tempNamesEmails, itemIndex);
  };

  return (
    <StyledCartItem>
      <div className="main-content">
        <div className="cart-item-details">
          <p className="cart-item-title">{item.title}</p>
          <p>
            <b>Start Date:</b> {formatToMilitaryDate(item.date)} <br />
            <b>End Date:</b> {formatToMilitaryDate(item.datestop)}
          </p>
          {item.note && (
            <p>
              <b>Note:</b> {item.note}
            </p>
          )}
        </div>

        <StyledQty>
          <span>Qty:</span>

          <input
            type="number"
            onChange={e => handleQtyChange(e, item.id)}
            className="item-count"
            value={item.qty}
            min={1}
          />
        </StyledQty>

        <div>(x ${item.price})</div>

        <div className="cart-item-actions">
          <div className="cart-item-costbig">
            ${Number(item.price * item.qty).toFixed(2)}
          </div>
          <button
            type="button"
            key={item.id}
            onClick={() => {
              handleDelete(item.id);
            }}
            className="btn btn-danger btn-sm"
          >
            Remove
          </button>
        </div>
      </div>

      <div>
        <p className="text-danger">
          Please set NAME and EMAIL for each attendee below:
        </p>

        {namesEmails &&
          namesEmails.map((nameEmail, z) => (
            <StyledNameEmailRow key={z}>
              <div className="namebox">
                <div className="form-group">
                  <label htmlFor={`name[${z}]`}>Name:</label>
                  <input
                    type="text"
                    name={`name[${z}]`}
                    className="form-control"
                    onChange={e => handleNameUpdate(e.target.value, z)}
                    value={namesEmails[z].name}
                  />
                </div>
              </div>

              <div className="emailbox">
                <div className="form-group">
                  <label htmlFor={`email[${z}]`}>Email:</label>
                  <input
                    type="email"
                    name={`email[${z}]`}
                    className="form-control"
                    onChange={a => handleEmailUpdate(a.target.value, z)}
                    value={namesEmails[z].email}
                  />
                </div>
              </div>
            </StyledNameEmailRow>
          ))}
      </div>
    </StyledCartItem>
  );
};

export default CartItem;
